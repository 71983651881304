<template>
  <div id="common">
    <div class="page" size="A4" v-for="page in pages" :key="'page' + page">
      <v-row class="pt-2">
        <v-col class="d-flex justify-start align-center">
          <div>
            <v-avatar size="50">
              <img
                src="https://casasdastintas.com.br/_nuxt/icons/icon_512x512.9bcf77.png"
              />
            </v-avatar>
          </div>
          <div>
            <div>
              <b style="font-size: 12px">Rede Casas das Tintas</b>
            </div>
          </div>
        </v-col>
        <v-col
          class="d-flex justify-end text-right align-center"
          style="font-size: 10px"
        >
          <b> Nº {{ stock_transfer.code }} </b>
        </v-col>
      </v-row>
      <!-- <v-row class="pt-2 align-center">
        <v-col cols="1">
          <v-avatar size="50">
            <v-img
              src="https://casasdastintas.com.br/_nuxt/icons/icon_512x512.9bcf77.png"
            />
          </v-avatar>
        </v-col>
        <v-col style="line-height: 12pt">
          <b style="font-size: 10px">Rede Casas das Tintas</b>
        </v-col>
        <v-col
          cols="3"
          class="d-flex flex-column text-center align-bottom"
          style="font-size: 10px; line-height: 15pt"
        >
          <b> Nº {{ stock_transfer.code }} </b>
        </v-col>
      </v-row> -->

      <hr class="mt-3 mb-4" />

      <v-row class="text-center">
        <v-col><h2>Romaneio de Transferência</h2> </v-col>
      </v-row>
      <v-row
        v-if="isFirstPage(page)"
        class="d-flex justify-space-between align-center mb-2"
      >
        <v-col class="d-flex flex-column">
          <span>
            <b>Descrição: </b>
            {{ stock_transfer.description }}
          </span>

          <span>
            <b> Origem: </b> {{ stock_transfer.company_origin.code }} -
            {{ stock_transfer.company_origin.name }}
          </span>

          <span>
            <b> Destino: </b>{{ stock_transfer.company_destination.code }} -
            {{ stock_transfer.company_destination.name }}
          </span>
        </v-col>

        <v-col cols="4" class="d-flex flex-column">
          <span>
            <b>Emissão:</b> {{ $format.dateTimeBr(stock_transfer.issued_at) }}
          </span>
          <span> <b>Criado por:</b> {{ stock_transfer.created_by.name }} </span>
          <span v-if="stock_transfer.canceled_by">
            <b>Recebido por:</b> {{ stock_transfer.created_by.name }}
            {{ $format.dateTimeBr(stock_transfer.received_at) }}
          </span>
          <span v-if="stock_transfer.received_by">
            <b>Cancelado por:</b> {{ stock_transfer.created_by.name }}
            {{ $format.dateTimeBr(stock_transfer.canceled_at) }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :page="page"
            :headers="headers"
            :items="getItems(page)"
            :items-per-page="itensPerPage"
            hide-default-footer
            disable-sort
            dense
          >
            <template v-slot:[`item.product`]="{ item }">
              <span v-if="item.product">
                {{ item.product.code }} - {{ item.product.name }}
              </span>
            </template>
            <template v-slot:[`item.quantity`]="{ item }">
              <span v-if="item.product">
                {{ $format.decimal(item.quantity) }}
                {{ item.product_variant.unity.symbol }}
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <template v-if="page == pages">
        <v-row style="word-break: break-word">
          <v-col> Observação: {{ stock_transfer.observations }} </v-col>
        </v-row>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stock_transfer: {},
  },
  data() {
    return {
      itensPerPage: 17,

      headers: [
        { text: "Produto", value: "product" },

        {
          text: "Quantidade	",
          value: "quantity",
          align: "center",
          width: "10%",
        },
      ],
    };
  },

  computed: {
    pages() {
      return Math.ceil(this.itensCount / this.itensPerPage);
    },

    transfer_products() {
      return this.stock_transfer.stock_transfer_products;
    },
    itensCount() {
      if (this.transfer_products) {
        return this.transfer_products.length;
      }
      return 1;
    },
  },
  methods: {
    hasItemOnIndex(page, item) {
      return this.getIndex(page, item) < this.itensCount;
    },
    isFirstPage(page) {
      return page == 1;
    },

    getItems(page) {
      let items = this.transfer_products.slice(
        (page - 1) * this.itensPerPage,
        page * this.itensPerPage
      );
      if (items.length < this.itensPerPage) {
        for (let i = items.length; i < this.itensPerPage; i++) {
          items.push({});
        }
      }
      return items;
    },
  },
};
</script>

<style  lang="scss">
@import "@/assets/style/prints/CommonPrinting.scss";
</style>
